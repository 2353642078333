export const lowerCacheOptions = {
  gcTime: 5 * 60 * 1000,
  staleTime: 5 * 60 * 1000,
  meta: {
    persist: true
  },
  refetchOnMount: true
};

export const pageSize = 50;
