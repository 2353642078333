import { Layout } from 'antd';
import styled from 'styled-components';
import radial from '@/assets/radial.png';
import airdropUrl from '@/assets/airdrop-banner.png';

export const Wrapper = styled(Layout)`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background: #000321;
  /* min-height: 100svh;
  padding-bottom: 120px; */
  .badge-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(180deg, #000321 0%, rgba(0, 3, 33, 0.7) 100%);
    z-index: 100000;
    padding: 16px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.7s;
  }
  .airdrop {
    background: url(${airdropUrl});
    background-size: 100% 100%;
    border-top: 1px solid #42467d;
    margin-top: 180px;
    .airdrop-bg {
      background: linear-gradient(180deg, rgba(0, 3, 33, 0.4) 0%, rgba(0, 3, 33, 0.28) 100%);
      backdrop-filter: blur(200px);
    }
  }
  .ld-pagination {
    border: none !important;
    outline: none !important;
    li {
      border: none;
      outline: none;
    }
  }
  /* .other-bg {
    background: linear-gradient(180deg, #460982 30%, #0f021c 100%);
  } */
  .ant-pagination-item-active a {
    color: #ffffff !important;
  }
  .gardient-bg {
    min-width: 600px;
    width: 100%;
    height: 0px;
    padding-top: 75%;
    background: url(${radial});
    background-size: 100% 100%;
    /* backdrop-filter: blur(200px); */
  }
  .card {
    background: #181e7999;
    backdrop-filter: blur(200px);
  }
  .primary-button {
    background: #ffffff;
    height: 64px;
    border-radius: 40px;
    padding-left: 24px;
    padding-right: 24px;
    span {
      margin-top: 3px;
      color: #000000 !important;
      font-size: 16px;
      font-weight: 600;
      font-family: 'Clash Display Semi';
    }
    &:hover {
      background: #ffffff !important;
      border: none !important;
      opacity: 0.85;

      text-align: left;
      span {
        color: #000000 !important;
        font-size: 16px;
        font-weight: 600;
        font-family: 'Clash Display Semi';
      }
    }
  }
  .secondary-button {
    background: #000321;
    height: 64px;
    border-radius: 40px;
    padding-left: 24px;
    padding-right: 24px;
    border: 1px solid #ffffff;
    span {
      font-size: 20px;
      font-weight: 600;
      color: #ffffff !important;
      font-family: 'Clash Display Semi';
    }
    &:hover {
      background: #000321 !important;
      border: 1px solid #ffffff !important;

      opacity: 0.85;

      text-align: left;
      span {
        font-size: 20px;
        font-weight: 600;
        color: #ffffff !important;
        font-family: 'Clash Display Semi';
      }
    }
  }
  .instruction-button {
    border: 1px solid #ffffff80;
    height: 40px;
    border-radius: 40px;
  }
  .copy-btn {
    background: #000321;
    border: 1px solid #ffffff;
    height: 40px;
    border-radius: 40px;
    padding-left: 18px;
    padding-right: 18px;
    width: fit-content;
    font-family: 'Clash Display Semi';
  }
  .badge {
    background: #181e7999;
    backdrop-filter: blur(200px);
  }
`;
