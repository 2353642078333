import { pageSize } from '@/constants/app.constant';
import http from '@/utilities/http.utils';

export class UserRepository {
  static getReferral(activePage: number): Promise<any> {
    return http.get(`/api/v1/referral/public_leaderboard?start=${(activePage - 1) * pageSize}&limit=${pageSize}`);
  }

  static getUserData(id: string): Promise<any> {
    return http.get(`/api/v1/referral/telegram_id_stats?telegram_id=${id}`);
  }
}
