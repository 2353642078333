import React from 'react';
import { useQuery } from '@tanstack/react-query';

interface IPropTimer {
  timeClose: number | '';
  onSetTimerDays: (days: string) => void;
  onSetTimerHours: (days: string) => void;
  onSetTimerMinutes: (days: string) => void;
  onSetTimerSeconds: (days: string) => void;
  onSetIsEnd: (isTrue: boolean) => void;
  countdownID?: string;
}

export const CountDownTimer = ({
  onSetTimerDays,
  timeClose,
  onSetTimerHours,
  onSetTimerMinutes,
  onSetTimerSeconds,
  onSetIsEnd,
  countdownID
}: IPropTimer) => {
  useQuery({
    queryKey: [`countdownInterval${countdownID || ''}`, timeClose, countdownID],
    queryFn: () => {
      if (timeClose === '') {
        return -1;
      }
      const now = new Date(new Date().toUTCString()).getTime();
      const distanceTime = timeClose - now;
      if (!isNaN(distanceTime)) {
        const days: number = Math.floor(distanceTime / (1000 * 60 * 60 * 24));
        const hours: number = Math.floor((distanceTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes: number = Math.floor((distanceTime % (1000 * 60 * 60)) / (1000 * 60));
        const seconds: number = Math.floor((distanceTime % (1000 * 60)) / 1000);
        if (distanceTime < 0) {
          onSetTimerDays('0');
          onSetTimerHours('0');
          onSetTimerMinutes('0');
          onSetTimerSeconds('0');
          onSetIsEnd(true);
        } else {
          onSetIsEnd(false);
          days < 10 ? onSetTimerDays('0' + days.toString()) : onSetTimerDays(days.toString());
          hours < 10 ? onSetTimerHours('0' + hours.toString()) : onSetTimerHours(hours.toString());
          minutes < 10 ? onSetTimerMinutes('0' + minutes.toString()) : onSetTimerMinutes(minutes.toString());
          seconds < 10 ? onSetTimerSeconds('0' + seconds.toString()) : onSetTimerSeconds(seconds.toString());
        }
      }
      return -1;
    },
    retry: false,
    refetchInterval: 1000,
    enabled: timeClose !== ''
  });
  return <></>;
};
export default CountDownTimer;
