import CountDownTimer from '@/components/countdown-timer/countdown-timer';
import React, { useState } from 'react';
interface IPropsCustomCountDown {
  onSetIsEnd: (isTrue: boolean) => void;
  timeClose: number | '';
  classNames?: string;
  countdownID?: string;
}
const TODCountdown = ({ onSetIsEnd, timeClose, classNames, countdownID }: IPropsCustomCountDown) => {
  const [timerDaysEnd, setTimerDaysEnd] = useState('00');
  const [timerHoursEnd, setTimerHoursEnd] = useState('00');
  const [timerMinutesEnd, setTimerMinutesEnd] = useState('00');
  const [timerSecondsEnd, setTimerSecondsEnd] = useState('00');
  return (
    <div className='clock min-w-[81px]'>
      <CountDownTimer
        onSetTimerDays={(time) => setTimerDaysEnd(time)}
        onSetTimerHours={(time) => setTimerHoursEnd(time)}
        onSetTimerMinutes={(time) => setTimerMinutesEnd(time)}
        onSetTimerSeconds={(time) => setTimerSecondsEnd(time)}
        timeClose={timeClose}
        onSetIsEnd={(isTrue) => onSetIsEnd(isTrue)}
        countdownID={countdownID}
      />
      <div className={classNames}>
        {parseInt(timerDaysEnd) > 0 && `${parseInt(timerDaysEnd)}d `}
        {parseInt(timerHoursEnd)}hr {parseInt(timerMinutesEnd)}m {parseInt(timerSecondsEnd)}s
      </div>
    </div>
  );
};

export default TODCountdown;
