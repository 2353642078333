import React, { memo } from 'react';
import { Wrapper } from './header.styled';
import Logo from '@/assets/Logo.png';

export const HeaderComponent = () => {
  return (
    <Wrapper className='flex flex-col items-center overflow-visible w-full h-[80px] justify-center max-mobile-big:h-[64px]'>
      <img src={Logo} className='w-[56px] max-mobile-big:w-12' alt='' />
    </Wrapper>
  );
};

export const Header = memo(HeaderComponent);

export default Header;
